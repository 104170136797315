.player-info {
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  }
  
  .player-info img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .player-details h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .player-details p {
    font-size: 16px;
    color: #666;
    margin: 0;
  }