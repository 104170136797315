html {
  height: 100%;
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  display: block;
 /* flex-direction: row ;
  flex-wrap: wrap;
  flex-flow: column;
  */
  padding-left: 0px;
  padding-right: 0px;
  padding: 0;   
}
body {    
  margin: 0px;
  font-family: "Open Sans", sans-serif;
  height: 100%;
/*  max-height: 100%;*/
  width: 100%;
  max-width: 100%;
  display: flex;
  padding-left: 0px;
  padding-right: 0px;
  padding: 0;
  background-color:black;
 /* background-color: snow;*/
}
#root{
 height: 100%;
  overflow-y: auto;
  background-color:black;
  width: 100%;
  max-width: 100%;
  display: block;

}
.App {
  text-align: center;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  display: block;
}
.inputStringNeutral{
  border-color: white;
  color: black;
}
.inputStringOK{
  border-color: green;
  color: green;
}
.inputStringError{
  border-color:red;
  color: red;
}

.no-scroll{
  overflow: hidden;
}
.baseAnimatonPage{
  position: absolute;
  top:25%;
  width: auto;
  height: 50%;
  left: 50%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
 /* background-color: #282c34;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.hiddenComponent{
  display: none;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
